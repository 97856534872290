import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import 'swiper/css/swiper.min.css';
import Swiper from 'swiper';
import Container from './__Container';
import Slide1 from 'imgs/1d1d/m/slide.png';
import Slide2 from 'imgs/1d1d/m/banner2.png';
import Slide3 from 'imgs/1d1d/m/banner3.png';

const Slides = styled.section`
  position: relative;
  width: 100vw;
  height: 100vh;
  .swiper-wrapper {
    width: 100%;
    height: 100%;
    .slide {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position-x: 100%;
      background-color: #ff7c34;
      hgroup {
        text-align: center;
        > h2 {
          color: #fff;
          font-size: 0.5rem;
          margin-bottom: 0.33rem;
          > em {
            color: #fff;
            font-weight: bold;
          }
        }
        > h3 {
          font-weight: 800;
          color: #fff;
          font-size: 0.24rem;
          line-height: 1.4;
          padding-left: 0.2rem;
        }
      }
      &.first {
        background-color: #fd7c3f;
        background-image: url(${Slide1});
        padding-top: 1.6rem;
        background-size: cover;
      }
      &.middle {
        background-image: url(${Slide2});
        hgroup {
          position: absolute;
          bottom: 1.1rem;
          text-align: center;
          width: 100%;
        }
      }
      &.last {
        background-image: url(${Slide3});
        background-size: cover;
        hgroup {
          position: absolute;
          bottom: 2.1rem;
          text-align: center;
          width: 100%;
        }
      }
    }
  }

  .swiper-pagination-bullet-active {
    background: #fff;
  }
`;
export default function Index() {
  const container = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      if (container) {
        new Swiper(container.current, {
          loop: true,
          pagination: {
            el: '.swiper-pagination'
          },
          autoplay: {
            delay: 5000
          }
        });
      }
    }, 300);
  }, []);
  return (
    <Container>
      <Slides ref={container} className="swiper-container">
        <div className="swiper-wrapper">
          <div className="swiper-slide slide first">
            <hgroup>
              <h2>
                以技术引领幼教行业<em>全面升级</em>
              </h2>
              <h3>
                Leading the overall upgrading of Preschool
                <br />
                Education Industry with Technology
              </h3>
            </hgroup>
          </div>
          <div className="swiper-slide slide middle">
            <hgroup>
              <h2>
                用爱和责任<em>推动幼儿教育公平</em>
              </h2>
              <h3>
                Promoting equity in early childhood education
                <br />
                with love and responsibility
              </h3>
            </hgroup>
          </div>
          <div className="swiper-slide slide last">
            <hgroup>
              <h2>
                壹点壹滴 <br />
                <em>做推动行业进步的幼教互联网企业</em>
              </h2>
              {/* <h3>The pioneer of Chinese preschool education industry</h3> */}
            </hgroup>
          </div>
        </div>
        <div className="swiper-pagination"></div>
      </Slides>
    </Container>
  );
}
